<template>
  <!-- 订单详情 -->
  <div class="type_area">
    <div class="order_detail" v-for="item in 3" :key="item">
      <!-- 订单编号 -->
      <div class="order_number">
        <div style="margin-bottom:5px;">
                                <el-checkbox >西安妙济堂医药有限公司</el-checkbox>
                                <!-- <span style="margin-left:20px;">支付方式:</span>
                                <span style="margin-left:20px;" class="payType">微信支付</span>
                                <span style="margin-left:20px;" class="payType">支付宝支付</span> -->

                            </div>
        <img style="margin-right: 4px;" src="@/assets/images/order/ddbh.svg" alt="">
        <span>订单编号：{{orderNumber}}</span>
      </div>
      <!-- 进度步骤 -->
      <div class="process_box" v-if="orderDetail.status&&orderDetail.status.length == 4">
        <div class="process_item" v-for="(item,index) in orderDetail.status" :key="index">
          <span class="item_title" :class="{active: item.isActive,hui:item.state}">
            <span v-if="index == 0">①</span>
            <span v-if="index == 1">②</span>
            <span v-if="index == 2">③</span>
            <span v-if="index == 3">④</span>
             {{item.name}}</span>
          <span style="height: 16px;">{{item.datetime}}</span>
        </div>
      </div>
      <div class="process_box kh" v-if="orderDetail.status&&orderDetail.status.length == 5">
        <div class="process_item" v-for="(item,index) in orderDetail.status" :key="index">
          <span class="item_title" :class="{active: item.isActive,hui:item.state}">
            <span v-if="index == 0">①</span>
            <span v-if="index == 1">②</span>
            <span v-if="index == 2">③</span>
            <span v-if="index == 3">④</span>
            <span v-if="index == 4">⑤</span>
             {{item.name}}</span>
          <span style="height: 16px;">{{item.datetime}}</span>
        </div>
      </div>
      <!-- 收货信息 -->
      <div class="receive_info">
        <div class="box_title">收货信息</div>
        <div class="info_box">
          <div class="info_item">
            <div class="info_title">{{orderDetail.contacter}} <span style="color: #CFCFCF;font-size: 12px;font-weight: 400;margin: -3px 6px 0px;">|</span> {{orderDetail.mobile}}</div>
            <div>{{orderDetail.address}}</div>
          </div>
          <div class="info_item">
            <div class="info_title">配送信息</div>
            <span v-if="!orderDetail.waybill||!orderDetail.waybill.length">--</span>
            <div class="delivery_item" v-else>
              <span>{{orderDetail.waybill[0].logisticsName||'--'}} <span v-if="orderDetail.waybill.length>1" class="more" @click="dialogVisible = true">更多配送信息<i class="el-icon-arrow-right icon"></i></span></span>
              <span>运单号：{{orderDetail.waybill[0].waybillNumber||'--'}}</span>
              <span>送达时间：{{orderDetail.waybill[0].deliveryTime||'--'}}</span>
            </div>
          </div>
          <div class="info_item">
            <div class="info_title">付款信息</div>
            <span>实付金额：{{orderDetail.paid?'￥'+(orderDetail.paid).toFixed(2):'--'}}</span>
            <span>支付时间：{{orderDetail.orderPaymentdate||'--'}}</span>
            <span>支付方式：{{orderDetail.paymentMethodName||'--'}}<span class="pay_img" v-if="orderDetail.paymentMethodName&&orderDetail.image" @click="imgDialog = true">支付凭证</span></span>
            <span>发票类型：{{orderDetail.invoice||'--'}}</span>
          </div>
        </div>
      </div>
      <!-- 商品列表-列表模式 -->
      <div class="list_mode">
        <div class="box_title">商品清单</div>
        <div class="goods_list">
          <div class="list_title">
            <span>商品信息</span>
            <span>单价</span>
            <span>数量</span>
            <span>金额</span>
            <span>操作</span>
          </div>
          <div class="list_item" v-for="item in orderDetail.goodsList" :key="item.goodsId">
            <div class="item_left">
              <!-- 商品图片 -->
              <div class="goods_img">
                <img :src="item.imageUrl" alt="">
              </div>
              <!-- 商品信息 -->
              <div class="goods_info">
                <div class="title">
                  <div class="tag" v-if="item.type == 2||item.type == 4||item.type == 5">
                    <span :class="{tj:item.type == 2,yh:item.type == 4||item.type == 5}">{{item.type==2?'特价':(item.type==4?'买赠':'赠品')}}</span>
                  </div>
                  <span>{{item.productName}}</span>
                </div>
                <div class="info_box">
                  <div class="info_item">
                    <div>
                      <span class="info_name">厂商</span>
                      <span>{{item.produceUnit||'--'}}</span>
                    </div>
                  </div>
                  <div class="info_item">
                    <div>
                      <span class="info_name">效期</span>
                      <span>{{item.lastDate||'--'}}</span>
                    </div>
                  </div>
                  <div class="info_item">
                    <div>
                      <span class="info_name">规格</span>
                      <el-tooltip class="item" effect="dark" :disabled="item.norms&&item.norms.length<30" :content="item.norms" placement="top-start">
                        <span class="spesc">{{item.norms||'--'}}</span>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="info_item">
                    <div>
                      <span class="info_name">库存</span>
                      <span>{{item.stock}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item_right price" v-html="initPrice(item.bidPrice)"></div>
            <div class="item_right number">{{item.quantity}}</div>
            <div class="item_right price font_weight_bold" v-html="initPrice(item.bidPrice*item.quantity)"></div>
            <div class="item_right action" @click="downClick(item)">下载首营资料</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 微信截图预览 -->
    <el-dialog class="img_pre" :visible.sync="imgDialog" width="800px">
      <div class="img_box" style="text-align: center;">
        <img style="max-width: 100%;max-height: 69vh;" :src="orderDetail.image" alt="" />
      </div>
    </el-dialog>
    <!-- 更多物流信息弹窗 -->
    <el-dialog
      class="dialog_box"
      title="更多配送信息"
      :visible.sync="dialogVisible"
      width="305px"
      center>
      <div class="dialog_main">
        <div class="delivery_item" v-for="(item,index) in orderDetail.waybill" :key="index">
          <span>{{item.logisticsName||'--'}}</span>
          <span>运单号：{{item.waybillNumber||'--'}}</span>
          <span>送达时间：{{item.deliveryTime||'--'}}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { downloadFile } from '@/utils/common'
export default {
  name: 'OrderDetail',
  data() {
    return {
      imgDialog: false,
      dialogVisible: false,
      price: 369.36,
      orderNumber: '',
      orderDetail: {},
      statusList:[]
    };
  },
  mounted(){
    this.orderNumber = this.$route.query.orderNumber||''
    this.getOrderDetail()
  },
  methods: {
    // 获取订单详情
    getOrderDetail(){
      this.$api.pcOrderDetail({orderNumber:this.orderNumber}).then((res) => {
        if (res.data.code === 200) {
          this.orderDetail = res.data.data
          this.statusList = this.orderDetail.status
          this.statusList.forEach((item,index)=>{
            if(index == this.statusList.length-1&&item.state){
              item.isActive = true
            }
            if(index+1<=this.statusList.length&&item.state&&!this.statusList[index+1].state){
              item.isActive = true
            }
          })
        }
      });
    },
    // 下载检验资料
  async  downClick(item){
        const res  = await this.$orderApis.downloadLicense({ goodsId: item.goodsId })
            //    if(res.data.code==100001) return this.$message.error(res.msg)
            if (res.status==200){
                this.isLoading=false
                  downloadFile(res)
            }
    },
  }
};
</script>
<style lang="scss" scoped>
.dialog_box{

  ::v-deep{
    .el-dialog{
      border-radius: 10px;
    }
    .el-dialog__header{
      text-align: left;
      font-size: 20px;
      color: #1A1A1A;
      font-weight: 600;
    }
    .el-dialog__headerbtn{
      top: 24px;
    }
    .el-dialog--center .el-dialog__body{
      padding-top: 6px;
    }
  }
  .dialog_main{
    .delivery_item{
      display: flex;
      flex-direction: column;
      font-size: 14px;
      color: #666666;
      margin-top: 7px;
      line-height: 22px;

      span:first-of-type{
        font-weight: bold;
        color: #333333;
      }
    }
  }
}
.order_detail{
  padding: 20px 24px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  margin-top: 16px;
  overflow: hidden;

  .top_number{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .order_number{
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid #E4E4E4;
    font-weight: bold;
    font-size: 18px;
    color: #333333;
  }

  .process_box{
    display: flex;
    align-items: center;
    margin-bottom: 9px;

    &.kh{
      .process_item{
        width: 238px;

        .item_title{
          background: url("~@/assets/images/order/bzs.png") no-repeat;
          background-size: 100% 100%;

          &.hui{
            background: url("~@/assets/images/order/bzs_a1.png") no-repeat;
            background-size: 100% 100%;
          }
          &.active{
            background: url("~@/assets/images/order/bzs_a.png") no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }

    .process_item{
      width: 294px;
      flex-shrink: 0;
      margin-left: -10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #666666;

      &:first-of-type{
        margin-left: 0;
      }

      .item_title{
        width: 100%;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background: url("~@/assets/images/order/bz.png");
        background-size: 100%;
        margin: 16px 0 6px;
        font-size: 16px;
        color: #333333
      }
      .item_title.hui{
        color: #fff;
        background: url("~@/assets/images/order/bz_a1.png");
      }
      .item_title.active{
        color: #fff;
        background: url("~@/assets/images/order/bz_a.png");
      }
    }
  }

  .box_title{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 16px;
    color: #333333;

    &::before{
      content: "";
      display: inline-block;
      width: 6px;
      height: 16px;
      background: #1A9DFF;
      border-radius: 100px 100px 100px 100px;
      margin-right: 7px;
    }
  }

  .receive_info{
    margin-bottom: 12px;

    .info_box{
      padding: 14px 16px;
      background: #F7F7F7;
      border-radius: 2px 2px 2px 2px;
      display: flex;
      align-items: flex-start;
      font-size: 12px;
      color: #666666;

      .info_item{
        width: 280px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 80px;

        .delivery_item{
          display: flex;
          flex-direction: column;

          .more{
            color: #1A9DFF;
            cursor: pointer;
            margin-left: 10px;
            &:hover{
              color: #0687FE;
            }
            .icon{
              font-weight: 600;
            }
          }
        }

        .pay_img{
          margin-left: 16px;
          color: #1A9DFF;
          cursor: pointer;
          &:hover{
            color: #0687FE;
          }
        }

        .info_title{
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: 14px;
          color: #333333;
          margin-bottom: 11px;
        }

        span{
          margin-bottom: 6px;
        }
      }
    }
  }

  .list_mode{
    .goods_list{
      border: 1px solid #EEEEEE;

      .list_title{
        display: flex;
        align-items: center;
        height: 40px;
        border-bottom: 1px solid #EEEEEE;
        font-size: 14px;

        span {
          flex: 1;
          text-align: center;

          &:first-of-type{
            flex: 3;
            text-align: left;
            text-indent: 71px;
            margin-left: 16px;
          }
        }
      }

      .list_item{
        position: relative;
        padding: 13px 0;
        border-bottom: 1px solid #EEEEEE;
        background: #fff;
        display: flex;
        align-items: center;

        &:hover{
          background: #F5FBFF;
        }

        .goods_img{
          margin-right: 18px;
          img{
            width: 130px;
            height: 130px;
          }
        }

        .goods_info{
          .title{
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 16px;
            color: #333333;
            margin-bottom: 13px;
            .tag{
              display: flex;
              align-items: center;

              span{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 17px;
                box-sizing: border-box;
                border-radius: 2px;
                font-size: 10px;
                line-height: 17px;
                margin-right: 4px;
              }

              .tj{
                background: rgba(248, 213, 213, 0.9);
                border: 1px solid #FFACAC;
                color: #F92B2B;
              }
              .zy{
                background: rgba(206, 231, 249, 0.9);
                border: 1px solid #B7DCF8;
                color: #1A9DFF;
              }
              .yh{
                width: auto;
                padding: 0 6px;
                background: rgba(248, 240, 223, .9);
                border: 1px solid #FAD894;
                color: #F7A400;
              }
            }
          }
          .info_box{
            .info_item{
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #333333;
              margin-bottom: 5px;

              &>div{
                width: 320px;
                margin-right: 16px;
                display: flex;
                align-items: center;
              }

              .info_name{
                color: #999999;
                margin-right: 12px;
              }
            }
          }

          .bottom{
            display: flex;
            align-items: center;
            margin-top: 7px;

            .price_box{
              font-size: 20px;
              color: #F34141;
              font-weight: 600;
            }

            .yh_box{
              position: relative;
              margin-left: 10px;
              padding-bottom: 2px;
              .yh{
                width: auto;
                padding: 2px 6px;
                background: rgba(248, 240, 223, .9);
                border: 1px solid #FAD894;
                border-radius: 4px;
                font-size: 12px;
                color: #F7A400;
              }
              .sj{
                position: absolute;
                left: -4px;
                top: 8px;
              }
            }
            
          }
          
        }

        .item_left{
          flex: 3;
          display: flex;
          margin-left: 16px;
        }

        .item_right{
          flex: 1;
          text-align: center;
          font-size: 18px;

          &.price{
            color: #F92B2B;
          }
          &.action{
            font-size: 14px;
            color: #1A9DFF;
            cursor: pointer;

            &:hover{
              color: #0687FE;
            }
          }
        }
      }
    }
  }
}
.spesc{
  display: inline-block;
  width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>